import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';
import cassavaFarm from '../assets/cassavaFarm.jpeg';
import cocoaImage from '../assets/cocoa2.jpeg';
import cashewImage from '../assets/cashew2.jpeg';
import cassavaImage from '../assets/cassava2.avif';
import maizeImage from '../assets/maize2.jpeg';
import sustainableFarmingImage from '../assets/sus.jpeg';

function HomePage() {
  const slideshowImages = ['./images/maizeHome.jpg', './images/cocoaHome1.jpeg', './images/cashewHome2.jpeg', './images/palmHome.webp', './images/cassavaHome.jpg'];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slideshowImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const cardsData = [
    {
      title: 'Cocoa',
      description: 'Cocoa is used to produce chocolate and other cocoa products, valued for its rich flavor and antioxidants.',
      image: cocoaImage,
    },
    {
      title: 'Cashew',
      description: 'Cashews are creamy, nutritious nuts used in cooking and snacking, rich in healthy fats and minerals.',
      image: cashewImage,
    },
    {
      title: 'Cassava',
      description: 'Cassava is a major carbohydrate source, used in various dishes and products such as fufu, garri, lafun.',
      image: cassavaImage,
    },
    {
      title: 'Maize',
      description: 'Maize, or corn, is a versatile crop used as food, animal feed, and in industry, rich in fiber and vitamins.',
      image: maizeImage,
    },
  ];

  return (
    <div className="homePage">
      <div className="slideshow">
        <img src={slideshowImages[currentIndex]} alt="Slideshow background" />
        <div className="slideshow-text">
          <h1>GREENFIELDS.</h1>
          <p>Providing Affordable High-Quality Agricultural Products.</p>
        </div>
      </div>

      <section className="about">
        <h2 className="aboutDesc">Organic Farm Products and Agricultural Solutions.</h2>
        <div className="imageCard">
          <img src={cassavaFarm} alt="Agriculture Image" className="aboutImage" />
        </div>
      </section>

      <section className="produce">
        <h2>Outputs</h2>
        <div className="cards">
          {cardsData.map((card, index) => (
            <div className="card" key={index}>
              <img src={card.image} alt={card.title} />
              <h5>{card.title}</h5>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
        <Link to="/#" className="see-all-produce">See all products</Link>
      </section>

      <section className="sustainable-farming">
        <img src={sustainableFarmingImage} alt="Sustainable Farming" className="sustainable-farming-image" />
        <div className="sustainable-farming-text">
          <h2>Traditional Farming Meets Technology: Building a Greener Future</h2>
          <Link to="/contact" className="contact-button">Contact Us</Link>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
