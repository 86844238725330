import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import logo from '../assets/Greenfields..svg';

function showSidebar(event) {
  event.preventDefault();
  const sidebar = document.querySelector('.sidebar');
  sidebar.style.display = 'flex';
}

function hideSidebar() {
  const sidebar = document.querySelector('.sidebar');
  sidebar.style.display = 'none';
}

function NavBar() {
  useEffect(() => {
    function handleClickOutside(event) {
      const sidebar = document.querySelector('.sidebar');
      if (sidebar && !sidebar.contains(event.target) && sidebar.style.display === 'flex') {
        hideSidebar();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav>
      <ul className='sidebar'>
        <li onClick={hideSidebar}>
          <a href="/#">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="black">
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </a>
        </li>
        <li onClick={hideSidebar}><Link to="/">Home</Link></li>
        <li onClick={hideSidebar}><Link to="/">Products</Link></li>
        <li onClick={hideSidebar}><Link to="/">Services</Link></li>
        <li onClick={hideSidebar}><Link to="/">About</Link></li>
        <li onClick={hideSidebar}><Link to="/contact">Contact Us</Link></li>
      </ul>
      <ul>
        <li><Link to="/"><img src={logo} alt="Greenfields Logo" /></Link></li>
        <li className='hideOnMobile'><Link to="/">Home</Link></li>
        <li className='hideOnMobile'><Link to="/">Products</Link></li>
        <li className='hideOnMobile'><Link to="/">Services</Link></li>
        <li className='hideOnMobile'><Link to="/">About</Link></li>
        <li className='hideOnMobile'><Link to="/contact">Contact Us</Link></li>
        <li className="menu-button" onClick={showSidebar}>
          <a href="/#">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#043f23">
              <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
