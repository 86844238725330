import React, { useState } from 'react';
import './Contact.css';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import emailjs from '@emailjs/browser';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState(''); // 'success' or 'error'

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_rwfiwag', // Replace with your EmailJS service ID
      'template_mk8bqbk', // Replace with your EmailJS template ID
      e.target,
      'eOO-6MqomiLg3Ry_m' // Replace with your EmailJS user ID
    ).then((result) => {
      setFeedback('Message sent successfully!');
      setFeedbackType('success');
      setTimeout(() => setFeedback(''), 3000); // Clear feedback after 3 seconds
    }, (error) => {
      setFeedback('Failed to send message. Please try again later.');
      setFeedbackType('error');
      setTimeout(() => setFeedback(''), 3000); // Clear feedback after 3 seconds
    });

    e.target.reset();
  };

  return (
    <div className="Contact">
      <div className="contact-header">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-container">
        <div className="contact-info">
          <h2>Work with us</h2>
          <p>Questions, comments, or suggestions? Simply fill in the form and we’ll be in touch shortly.</p>
          <div className="contact-details">
            <p>📍 22, WASIMI STREET, OKE-IGBO, ONDO STATE, NIGERIA.</p>
            <p><a href="tel:+2347049998075">📞 +234 704 999 8075</a></p>
            <p><a href="mailto:contact@greenfields.ng">✉️ Contact@greenfields.ng</a></p>
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" name="firstName" placeholder="First Name*" required onChange={handleChange} />
            <input type="text" name="lastName" placeholder="Last Name*" required onChange={handleChange} />
          </div>
          <input type="email" name="email" placeholder="Email*" required onChange={handleChange} />
          <input type="tel" name="phone" placeholder="Phone Number*" required onChange={handleChange} />
          <textarea name="message" placeholder="Your message..." required onChange={handleChange}></textarea>
          <button type="submit">Send Message</button>
        </form>
        {feedback && <div className={`notification ${feedbackType}`}>{feedback}</div>}
      </div>
    </div>
  );
}

export default Contact;
