import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import App from '../App';
import HomePage from '../Pages/HomePage';
import Contact from '../Pages/Contact';
// import Products from './Products';  
// import Services from './Services';  
// import About from './About';        
// import Careers from './Careers';  

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path="contact" element={<Contact />} />
          {/* <Route path="browse" element={<ProductListing />} />
          <Route path="profile" element={<Profile />} />
          <Route path="product/:cardId" element={<ProductItem />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}
